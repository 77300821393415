<template>
  <div class="agenda">
    <div @click="handleEnterLaunchOneToOne">
      <slot></slot>
    </div>
    <div class="agendItem">
      <el-dialog
        :title="$t('meeting1v1')"
        :visible.sync="agendaVisible"
        width="600px"
        class="videoPlayDialog"
      >
        <template v-if="!isOrderSuccess">
          <el-form
            ref="agendaForm"
            :label-width="LOCALE == 'en' ? '125px' : '90px'"
            v-loading="loading"
            :model="agendaForm"
          >
            <el-form-item>
              <template slot="label">
                <div class="labelSlot">{{ $t("appointmentData") }}</div>
              </template>
              <el-form-item prop="day">
                <el-select
                  v-model="agendaForm.day"
                  :placeholder="$t('appointmentData')"
                  size="small"
                  @change="selectAgendaChange"
                  style="width: 100%"
                >
                  <el-option
                    v-for="(item, index) in agendaList"
                    :key="item.date"
                    :label="item.date"
                    :value="item.date"
                    :disabled="item.now_time > item.timer"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form-item>
            <el-form-item class="time">
              <template slot="label">
                <div class="labelSlot">{{ $t("orderTitle") }}</div>
              </template>
              <el-form-item>
                <div>
                  <el-radio-group
                    v-model="activeRadio"
                    size="mini"
                    @change="radioChange"
                  >
                    <el-radio-button
                      :label="index"
                      v-for="(it, index) in selectAgenda.dataList"
                      :key="index"
                      :disabled="it.now_time > it.end_time"
                    >
                      {{ it.start_time | secondFormat("T") }} -
                      {{ it.end_time | secondFormat("T") }}
                    </el-radio-button>
                  </el-radio-group>
                </div>
              </el-form-item>
            </el-form-item>
            <el-form-item class="time">
              <template slot="label">
                <div class="labelSlot">{{ $t("appointmentTime") }}</div>
              </template>
              <el-form-item prop="end_time">
                <el-time-select
                  @change="startTimeChange"
                  size="small"
                  :placeholder="$t('Starttime')"
                  v-model="agendaForm.start_time"
                  :picker-options="startPicker"
                  :disabled="!activeRadio && activeRadio !== 0"
                >
                </el-time-select>
                <span>-</span>
                <el-time-select
                  :readonly="agendaForm.start_time ? true : false"
                  :picker-options="endPicker"
                  :disabled="!activeRadio && activeRadio !== 0"
                  size="small"
                  :placeholder="$t('endTime')"
                  v-model="agendaForm.end_time"
                >
                </el-time-select>
              </el-form-item>
            </el-form-item>
            <el-form-item prop="send_postscript" :label="$t('notes')">
              <el-input
                type="textarea"
                :placeholder="$t('entercontent')"
                v-model="agendaForm.send_postscript"
                maxlength="200"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <div></div>
            </el-form-item>
          </el-form>
          <div slot="footer">
            <el-button @click="agendaVisible = false" size="small">{{
              $t("cancel")
            }}</el-button>
            <el-button type="primary" size="small" @click="confimAgenda">{{
              $t("confirm")
            }}</el-button>
          </div>
        </template>
        <template v-else>
          <div class="tc sucessIcon clearfix">
            <i class="el-icon-success mainColor"></i>
            <div>Submit Succeed, waiting confirmation</div>
            <div class="fr btn">
              <el-button
                type="primary"
                size="small"
                @click="agendaVisible = false"
                >{{ $t("confirm") }}</el-button
              >
            </div>
          </div>
        </template>
      </el-dialog>
    </div>
    <setTalkDialog ref="setTalk"></setTalkDialog>
  </div>
</template>
<script>
import { formatDateTz } from "~/baseUtils";
import { secondFormat } from "~/basePlugins/filters";
import setTalkDialog from "~nbk/components/talk/setTalk";

export default {
  name: "agendaDialog",
  components: {
    setTalkDialog,
  },
  props: {
    info: {
      type: Object,
    },
  },
  watch: {
    timeScope() {
      return this.timeScope;
    },
  },
  data() {
    return {
      agendaForm: {
        start_time: "",
        day: "",
        end_time: "",
        send_postscript: "",
      },
      activeRadio: "",
      isOrderSuccess: false,
      selectAgenda: {},
      startPicker: {},
      endPicker: {},
      agendaList: [],
      loading: false,
      agendaVisible: false,

      companyCatalog1v1Error: false, // 提示弹出维护公司信息页面，填写完公司信息后再“发起1v1"
    };
  },
  created() {},
  mounted() {},
  methods: {
    async confimAgenda() {
      if (!this.agendaForm.day) {
        return false;
      }
      let startTime = null;
      let endTime = null;
      let nowTime = moment(new Date()).unix();
      let time = this.agendaForm.start_time;
      let hour = Number(time.split(":")[0]);
      let min = Number(time.split(":")[1]);
      let s = Number(hour * 3600) + Number(min * 60);
      startTime = (s + moment(this.agendaForm.day).unix()).valueOf();
      if (this.agendaForm.end_time) {
        let time = this.agendaForm.end_time;
        let hour = Number(time.split(":")[0]);
        let min = Number(time.split(":")[1]);
        let s = Number(hour * 3600) + Number(min * 60);
        endTime = (s + moment(this.agendaForm.day).unix()).valueOf();
      }
      let dayTime = moment(this.agendaForm.day).unix();
      let newTimeObj = {
        start_time: startTime ? startTime : "",
        end_time: endTime ? endTime : "",
        day: dayTime ? dayTime : "",
        send_postscript: this.agendaForm.send_postscript,
      };
      if (!newTimeObj.day) {
        this.$message.warning(this.$t("companyCatalog1v1Error4")); //日期不能为空
        return;
      } else if (!newTimeObj.start_time) {
        this.$message.warning(this.$t("companyCatalog1v1Error5")); //开始时间不能为空
        return;
      } else if (!newTimeObj.end_time) {
        this.$message.warning(this.$t("companyCatalog1v1Error6")); //结束时间不能为空
        return;
      } else if (newTimeObj.start_time && newTimeObj.start_time < nowTime) {
        this.$message.warning(this.$t("companyCatalog1v1Error7")); //"开始时间不能低于当前时间"
        return;
      } else if (newTimeObj.end_time - newTimeObj.start_time > 1801) {
        this.$message.warning("时间不能超过30分钟"); //不会发生
        return;
      } else if (
        newTimeObj.start_time &&
        newTimeObj.end_time &&
        newTimeObj.start_time >= newTimeObj.end_time
      ) {
        this.$message.warning("开始时间不能大于结束时间"); //不会发生
        return;
      } else {
        this.$emit("dataChange", newTimeObj);
        let params = {
          start_time: newTimeObj.start_time,
          end_time: newTimeObj.end_time,
          send_user_id: this.USER_INFO.id,
          in_user_id: this.info.user_id,
          source: this.PJSource,
          daily_source: 1,
          related_id: this.MEETING_ID,
        };
        this.btnLoading = true;
        let checkStatus = await this.$store.dispatch(
          "baseStore/checkChatStatus",
          params
        );
        let pp = {
          start_time: newTimeObj.start_time,
          end_time: newTimeObj.end_time,
          send_user_id: this.USER_INFO.id,
          in_user_id: this.info.user_id,
          source: this.PJSource,
          send_company_id: this.USER_INFO.company_id,
          in_company_id: this.info.company_id,
          day: newTimeObj.day,
          send_postscript: newTimeObj.send_postscript,
          in_postscript: "",
          daily_source: 1,
          related_id: this.MEETING_ID,
        };

        if (checkStatus && checkStatus.success) {
          this.send1v1(pp);
        } else {
          if (checkStatus.errorcode == 1028 || checkStatus.errorcode == 1033) {
            // 此时间段已握手成功的有会议
            this.$confirm(
              this.$t("haved_meetingd_meeting"),
              this.$t("tipsTitle"),
              {
                //您此时间段已有会议，是否继续邀约?
                confirmButtonText: this.$t("Confirm"),
                cancelButtonText: this.$t("cancel"),
                type: "warning",
              }
            )
              .then(() => {
                this.send1v1(pp);
              })
              .catch(() => {
                this.btnLoading = false;
                this.agendaVisible = false;
              });
          }
          if (checkStatus.errorcode == 1000) {
            //已存在邀约
            this.$confirm(this.$t("haved_same_meeting"), this.$t("tipsTitle"), {
              //您在此时间段已对该用户发过邀约
              confirmButtonText: this.$t("Confirm"),
              cancelButtonText: this.$t("cancel"),
              type: "warning",
            })
              .then(() => {
                this.btnLoading = false;
                // this.agendaVisible = false;
              })
              .catch(() => {
                this.btnLoading = false;
                // this.agendaVisible = false;
              });
          }
        }
      }
    },
    async send1v1(params) {
      let sendeve = await this.$store.dispatch(
        "baseStore/sendDailyEve",
        params
      );
      if (sendeve && sendeve.success) {
        if (sendeve.is_first) {
          this.isOrderSuccess = true;
        } else {
          this.agendaVisible = false;
          this.$message.success(this.$t("orderSuccess"));
        }
      }
      this.btnLoading = false;
    },
    async getList() {
      let params = {
        meeting_id: this.MEETING_ID,
        user_id: this.info.user_id,
        nopage: 1,
      };
      let res = await this.$store.dispatch(
        "baseConsole/getnegotationsetList",
        params
      );
      if (res.success) {
        let nowTime = moment(new Date()).unix();
        res.data.forEach((it) => {
          it.now_time = nowTime;
        });
        this.agendaList = formatDateTz(res.data);
        console.log(this.agendaList);
        let today = moment().startOf("day").unix();
        this.agendaList.forEach((it) => {
          it.now_time = today;
          it.timer = moment(it.date).unix();
        });
        this.changeTime();
      }
    },
    selectAgendaChange(e) {
      this.agendaForm.start_time = "";
      this.agendaForm.end_time = "";
      let obj = this.agendaList.find((it) => {
        return it.date == e;
      });
      this.selectAgenda = obj;
      this.activeRadioChange();
    },
    startTimeChange(val) {
      if (!val) {
        this.agendaForm.end_time = "";
        return;
      }
      let time = val;
      let hour = Number(time.split(":")[0]);
      let min = Number(time.split(":")[1]);
      let s = Number(hour * 3600) + Number(min * 60);
      let startTime;
      let day = moment().startOf("day").unix();
      startTime = s + day + 1800;
      this.agendaForm.end_time = secondFormat(startTime, "T");
    },
    handleEnterLaunchOneToOne() {
      if (this.info.user_id == this.USER_INFO.id) {
        return;
      }
      if (this.IS_LOGIN) {
        this.getNegotationList();
      } else {
        this.$router.push("/sign");
      }
    },
    async getNegotationList() {
      let params = {
        meeting_id: this.MEETING_ID,
        user_id: this.USER_INFO.id,
        nopage: 1,
        is_self: true,
      };
      let res = await this.$store.dispatch(
        "baseConsole/getnegotationsetList",
        params
      );
      if (res.success) {
        let howHour = (res.total_time / 3600).toFixed(1);
        this.$store.commit("SET_TALK_DURATION", howHour);
        if (howHour < 10) {
          this.$refs.setTalk.hintVisibleShow(true);
        } else {
          let setHour = (this.info.total_set_time_64 / 3600).toFixed(1);
          if (setHour < 10) {
            this.$message.warning("He/She doesn't set up available time.");
          } else {
            //   弹出预约框
            this.clearDate();
            this.agendaVisible = true;
            this.isOrderSuccess = false;
            this.getList();
          }
        }
      }
    },
    clearDate() {
      this.agendaForm = {
        start_time: "",
        day: "",
        end_time: "",
        send_postscript: "",
      };
    },
    radioChange(e) {
      let timer = this.selectAgenda.dataList[e];
      let start_begin;
      let endStart = secondFormat(timer.start_time + 1800, "T");
      let endTime = secondFormat(timer.end_time, "T");
      timer.start = secondFormat(timer.start_time, "T");
      timer.end = secondFormat(timer.end_time - 1800, "T");
      let activeTime = secondFormat(timer.end_time, "LL");
      if (
        moment(activeTime).startOf("day").unix() ==
        moment().startOf("day").unix()
      ) {
        start_begin = secondFormat(moment(new Date()).unix() - 60, "T");
      } else {
        start_begin = "";
      }
      this.startPicker = {
        start: timer.start,
        step: "00:30",
        end: timer.end,
        minTime: start_begin,
      };
      this.endPicker = {
        start: endStart,
        step: "00:30",
        end: endTime,
        minTime: start_begin,
      };
      this.agendaForm.start_time = "";
      this.agendaForm.end_time = "";
    },
    changeTime() {
      let nowTime = moment().startOf("day").unix();
      let obj = {};
      let arrIndex;
      let arrIndex1 = this.agendaList.findIndex(
        (it) => nowTime < moment(it.date).startOf("day").unix()
      ); // 返回子项的下标
      this.agendaList.forEach((it, index) => {
        if (moment(it.date).startOf("day").unix() == nowTime) {
          obj = it;
          return;
        } else if (moment(it.date).unix() > nowTime) {
          arrIndex = 0;
          return;
        } else {
        }
      });
      if (JSON.stringify(obj) != "{}") {
        this.agendaForm.day = obj.date;
        this.selectAgenda = obj;
      } else if (arrIndex1 >= 0 || arrIndex === 0) {
        let index = arrIndex1 || arrIndex;
        this.agendaForm.day = this.agendaList[index].date;
        this.selectAgenda = this.agendaList[index];
      } else {
        this.agendaForm.day = "";
        this.selectAgenda = {};
      }
      if (this.agendaForm.day) this.activeRadioChange();
    },
    activeRadioChange() {
      let arrIndex = this.selectAgenda.dataList.findIndex(
        (it) => it.now_time < it.end_time
      ); // 返回子项的下标
      this.activeRadio = arrIndex && arrIndex > 0 ? arrIndex : 0;
      this.radioChange(this.activeRadio);
    },
  },
};
</script>
<style scoped lang="less">
.time {
  .el-date-editor {
    width: 100%;
  }

  /deep/ .el-form-item__content {
    display: flex;
  }
}

.labelSlot::before {
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}

.sucessIcon {
  font-size: 16px;
  i {
    font-size: 72px;
  }
  .btn {
    padding-right: 24px;
  }
  div {
    margin: 24px 0 16px;
  }
}
.mt {
  margin-top: 15px;
}

.bt {
  margin-bottom: 10px;
}

.notopen {
  text-indent: 2em !important;
}

.color0083F6 {
  color: #0083f6;
}
.agendItem {
  /deep/ .el-dialog__body {
    padding: 30px 30px 0 30px !important;
  }
}

/deep/.el-textarea,
/deep/.el-form-item .el-form-item {
  width: 95%;
}

/deep/ .el-radio-button {
  margin-right: 8px;
  margin-bottom: 8px;
}
/deep/ .el-radio-button__inner {
  padding: 7px;
  border: 0;
  background: #fff;
  border-radius: 5px !important;
  border: 1px solid #027fff !important;
  color: #027fff;
}
</style>
