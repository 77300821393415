<template>
  <div class="item">
    <p class="top" @click="showCompanyInfo(item)">
      {{ item.company_name_en | priorFormat(item.company_name_zh, LOCALE) }}
    </p>
    <div class="message" @click="detail_dialog(item)">
      <img :src="item.avatar ? item.avatar : DEFAULT_AVATAR" alt="" />
      <div>
        <h1 class="textOverflow">
          {{ item.name_en | priorFormat(item.name_zh, LOCALE) }}
        </h1>
        <small class="textOverflow"
          >{{ item.job_title_en | priorFormat(item.job_title_zh, LOCALE) }}
        </small>
        <!-- <span class="textOverflow">{{
          item.industry | industryFormat(LOCALE)
        }}</span> -->
      </div>
    </div>
    <!-- 状态注释 -->
    <!-- <div class="statusBox" >
      <div v-if="item.is_online == 1">
        <i style="background: #5bda22"></i
        ><span>{{ $t("status_Online") }}</span>
      </div>
      <div v-if="item.is_online == 2">
        <i style="background: #ff5155"></i><span>{{ $t("status_Busy") }}</span>
      </div>
      <div v-if="item.is_online == 3">
        <i style="background: #c0c4cc"></i
        ><span>{{ $t("status_Offline") }}</span>
      </div>
    </div> -->
    <div class="footer">
      <div class="l">
        <i class="el-icon-location"></i>
        <span class="textOverFlow">{{
          item.country
            | countryCityFormat(
              item.company_region_en,
              item.company_region_zh,
              LOCALE
            )
        }}</span>
      </div>
      <div class="r">
        <agendaDialog :info="item"
          ><el-button
            type="primary"
            size="small"
            plain
            :disabled="item.user_id == USER_INFO.id"
            >{{ $t("meeting1v1") }}</el-button
          ></agendaDialog
        >
        <!-- <el-button
          v-if="!slotType"
          type="primary"
          size="small"
          plain
          @click="handleSubscribe(item)"
          >{{ $t("meeting1v1") }}</el-button
        > -->
        <!-- <chatBtn :oToId="item.one2one_id" :item="item"></chatBtn> -->
        <!-- {{ $t("Make_Appointment") }} -->

        <!-- <el-button size='small' type='primary' @click="noOpen">{{ $t("meeting1v1") }}</el-button> -->
      </div>
    </div>
    <el-dialog
      :title="$t('enterMeetingRoom')"
      :visible.sync="in_thack_show"
      width="30%"
    >
      <div class="thackBox">
        <div class="left">
          <div id="idCode" style="margin: 10px 20px"></div>
          <span>{{ $t("scanQRCodeRoom") }}</span>
        </div>
        <div class="right">
          <el-button type="primary" size="small" @click="d_in">
            {{ $t("enterDirectly") }}
          </el-button>
        </div>
      </div>
    </el-dialog>
    <!-- <el-dialog
      :title="$t('meeting1v1')"
      :visible.sync="chat1v1Show"
      width="1200px"
    >
      <userChat :miniInfo="chatInfo"></userChat>
    </el-dialog> -->
    <!-- 预约洽谈 -->
    <dailyChatOrder
      @eveSuccess="eveSuccess"
      @orderVisibleShow="orderVisibleShow"
      :item="item"
      :orderVisible="orderVisible"
    ></dailyChatOrder>
   
  </div>
</template>
<script>
import chatBtn from "~/baseComponents/chat/1v1ChatBtn";
import agendaDialog from "~nbk/components/talk/orderDialog";
import dailyChatOrder from "~/baseComponents/dailyChatOrder";
import userChat from "~/baseComponents/chat/userChat";

export default {
  props: {
    item: {
      type: Object,
      default: function () {
        return {
          top: "ZHANGJIAGANG HAOHUA INTERNATIONAL FREIGHT FORWARDERS CO., LTD",
          img: "",
          status: "",
          name: "Rico Balingcongan",
          company: "Customer Service Manager",
          yewu: "Logistics Supplier - Freight Forwarder",
          country: "Pampanga",
          city: "Philippines",
        };
      },
    },
  },
  components: {
    agendaDialog,
    dailyChatOrder,
    userChat,
    chatBtn,
    
  },
  data() {
    return {
      in_thack_show: false,
      chat1v1Show: false,
      companyInfo: {},
      orderVisible: false,
      slotType: false,
      chatInfo: {},
    };
  },
  mounted() {
    this.slotType = this.$scopedSlots.$hasNormal;
  },
  methods: {
    showCompanyInfo(item) {
     
      this.$emit('showCompany',item.company_id)
    },
    detail_dialog(row) {
      console.log(row);
      this.$GLOBALEVENT.$emit("OPENUSERCard", { show: true, row });
    },
    // 进入展台
    goDetail(item) {
      this.$router.push({
        path: "/boothDetail",
        query: {
          company_id: item.company_id,
          booth_id: item.meeting_booth_id,
        },
      });
    },
    noOpen() {
      this.$message.warning(this.$t("commingSoon"));
    },
    // 预约
    // async handleSubscribe(row) {
    //   this.checkSignEnrollExhibitor({
    //     todo: () => {},
    //     type: "sign",
    //   });

    //   if (moment().unix() > meeting_end_time) {
    //     // 会议结束
    //     this.$message.warning(this.$t("meeting_end"));
    //     return;
    //   }

    //   if (this.USER_INFO.id == row.user_id) {
    //     // 自己不能邀约自己
    //     this.$message.warning(this.$t("can_not_self"));
    //     return;
    //   }

    //   if (this.$store.state.baseStore.enrollInfo.is_user_enroll == 0) {
    //     //未报名
    //     this.$store.state.isApplyShow = true;
    //     this.checkSignEnrollExhibitor({
    //       todo: () => {},
    //       type: "notBtn",
    //     });
    //     return;
    //   }
    //   if (this.$store.state.baseStore.enrollInfo.audit_status == 0) {
    //     // 待审核
    //     this.$message.warning(this.$t("exp_not_shenhe"));
    //     return;
    //   }
    //   if (this.$store.state.baseStore.enrollInfo.audit_status == 2) {
    //     // 拒绝
    //     this.$message.warning(this.$t("exp_not_tongguo"));
    //     return;
    //   }
    //   let meeting_end_time = 1619193599;
    //   if (row.is_online != 1) {
    //     this.chat1v1Show = true;
    //     this.chatInfo = {
    //       user_id: 52049, //row.user_id,
    //       live_id: 1594878083000, //row.live_id,
    //     };
    //   } else {
    //     let params = {
    //       user_id: this.USER_INFO.id,
    //       company_id: this.USER_INFO.company_id,
    //       meeting_id: this.$store.state.meeting_id,
    //     };

    //     this.orderVisible = true;
    //     this.companyInfo.user_id = row.user_id;
    //     this.companyInfo.company_id = row.company_id;
    //   }
    // },
    //预约成功
    eveSuccess(value) {},
    // 预约洽谈弹框的显示与隐藏
    orderVisibleShow(value) {
      this.orderVisible = false;
    },
    // 立即洽谈
    handleNow(e) {
      this.in_thack_show = true;
      let url = "www.baidu.com";
      this.$nextTick(() => {
        console.log("++++++++", this.qrcode);
        if (!this.qrcode) {
          console.log(1111);
          this.qrcode = new QRCode("idCode", {
            text: url,
            width: 160,
            height: 160,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H,
          });
        } else {
          console.log(2222);

          this.qrcode.clear();
          this.qrcode.makeCode(url);
        }
      });
    },
    d_in() {
      alert("直接进入");
    },
  },
};
</script>
<style lang="less" scoped>
.item {
  width: 420px;
  height: 241px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #e9e9e9;
  margin-right: 20px;
  margin-bottom: 16px;
  padding: 16px;
  .top {
    cursor: pointer;
    height: 40px;
    font-size: 14px;
    font-weight: 400;
    color: #909399;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .message {
    cursor: pointer;
    height: 80px;
    margin-top: 16px;
    display: flex;
    img {
      overflow: hidden;
      width: 72px !important;
      height: 72px !important;
      border-radius: 36px;
    }
    div {
      padding-left: 16px;
      display: flex;
      flex-direction: column;
      width: 305px;
      h1 {
        height: 24px;
        line-height: 24px;
        font-size: 16px;
        font-weight: 500;
        color: #1f292e;
        margin-bottom: 6px;
        margin-top: 3px;
      }
      small {
        height: 22px;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 22px;
      }
      span {
        height: 24px;
        font-size: 12px;
        font-weight: 400;
        color: #415058;
        line-height: 24px;
      }
    }
  }
  .statusBox {
    width: 80px;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 4px;
      i {
        width: 6px;
        height: 6px;
        border-radius: 3px;
      }
      span {
        padding-left: 6px;
      }
    }
  }
  .footer {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .l {
      display: flex;
      align-items: flex-end;
      i {
        color: #999999;
        font-weight: bold;
        width: 13px;
        height: 17px;
        font-size: 18px;
      }
      span {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        padding-left: 6px;
      }
    }
    .r {
      display: flex;
      align-items: center;
      .btn2 {
        margin-left: 10px;
      }
    }
  }
  .thackBox {
    display: flex;
    align-items: center;
    justify-content: center;
    .left {
      margin-right: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 150px;
        height: 150px;
      }
    }
  }
}
</style>